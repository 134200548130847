import { TeamPermissionsModel } from './team.model';

export class UserModel {
  id: string;
  role: number;
  email: string;
  recipientNoEmail: boolean;
  username: string;
  displayName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  description: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressPostalCode: string;
  addressCountry: string;
  timeZone: string;
  cellPhone: string;
  homePhone: string;
  workPhone: string;
  dob: Date;
  state: number;
  lastLogin: Date;
  avatarUrl: string;
  logLevelMobile: string;
  logLevelWeb: string;
  walkthrough: number;
  invitedBy: string;
  promoCode: string;
  tosAccepted: Date;
  created: Date;
  updated: Date;
  deleted: Date;
  teams: Array<UserTeamsModel>;
  referer: string;
  bucEnabled: boolean
  clientId?: string;
  client?: string;
  // for coaches
  bio: string;

  // for new admin
  passwordHash?: string;

  primaryLanguage?: string;
  migrated?: boolean;
  dobTime: string;
  public originalInvitedByEmail?: string
  public originalClientId?: string
  roleDescription: string;
  stateDescription: string;
}

export class NewUser extends UserModel{
  password?: string;
}

export class UserTeamsModel {
  teamName?: string;
  teamRoleId: number;
  teamId: string;
  permissions: Array<TeamPermissionsModel>;
}

export class Users {
  items: Array<UserModel>;
}

export class UserRoles {
  static USER = 1
  static COACH = 2
  static COACHING_ADMIN = 3
  static SYSTEM_MANAGER = 4
  static ADMIN = 5
  static CLIENT_MANAGER = 6
  static SUPPORT = 7
}


export class UserConstants {
  // default avatar
  DEFAULT_AVATAR = '/assets/img/default-avatar.png';

  // system user id
  SYSTEM_USER_ID = '00000000-0000-0000-0000-000000000000';
  SYSTEM_AVATAR = '/assets/img/eLogo-Icon-Avatar-100x100-(70x76).png';

  USER_ROLES = [
    { number: 1, name: 'USER'},
    { number: 2, name: 'COACH'},
    { number: 3, name: 'COACHING_ADMIN'},
    { number: 4, name: 'SYSTEM_MANAGER'},
  ]

  USER_ROLES_NAMES = [
    { number: 1, name: 'User'},
    { number: 2, name: 'Coach'},
    { number: 3, name: 'Coaching Admin'},
    { number: 4, name: 'System Manager'},
    { number: 5, name: 'Admin'},
    { number: 6, name: 'Client Manager'},
    { number: 7, name: 'Support'},
  ]

  // state
  REGISTERED = 0;
  INVITED = 1;
  ACTIVE = 2;
  RESET_PASS = 3;
  TEST = 4;
  DELETED = 99;

  // user states allowed for admin change
  ADMIN_USER_STATES = [
    { number: 0, name: 'REGISTERED'},
    { number: 1, name: 'INVITED'},
    { number: 2, name: 'ACTIVE'},
    { number: 3, name: 'RESET_PASS'},
    { number: 4, name: 'TEST'},
    { number: 9, name: 'DELETED'},
    { number: 99, name: 'DELETED'}
  ];

  // address states
  US_STATES = [
    { N2: 'AL', name: 'Alabama' },
    { N2: 'AK', name: 'Alaska' },
    { N2: 'AZ', name: 'Arizona' },
    { N2: 'AR', name: 'Arkansas' },
    { N2: 'CA', name: 'California' },
    { N2: 'CO', name: 'Colorado' },
    { N2: 'CT', name: 'Connecticut' },
    { N2: 'DE', name: 'Delaware' },
    { N2: 'FL', name: 'Florida' },
    { N2: 'GA', name: 'Georgia' },
    { N2: 'HI', name: 'Hawaii' },
    { N2: 'ID', name: 'Idaho' },
    { N2: 'IL', name: 'Illinois' },
    { N2: 'IN', name: 'Indiana' },
    { N2: 'IA', name: 'Iowa' },
    { N2: 'KS', name: 'Kansas' },
    { N2: 'KY', name: 'Kentucky' },
    { N2: 'LA', name: 'Louisiana' },
    { N2: 'ME', name: 'Maine' },
    { N2: 'MD', name: 'Maryland' },
    { N2: 'MA', name: 'Massachusetts' },
    { N2: 'MI', name: 'Michigan' },
    { N2: 'MN', name: 'Minnesota' },
    { N2: 'MS', name: 'Mississippi' },
    { N2: 'MO', name: 'Missouri' },
    { N2: 'MT', name: 'Montana' },
    { N2: 'NE', name: 'Nebraska' },
    { N2: 'NV', name: 'Nevada' },
    { N2: 'NH', name: 'New Hampshire' },
    { N2: 'NJ', name: 'New Jersey' },
    { N2: 'NM', name: 'New Mexico' },
    { N2: 'NY', name: 'New York' },
    { N2: 'NC', name: 'North Carolina' },
    { N2: 'ND', name: 'North Dakota' },
    { N2: 'OH', name: 'Ohio' },
    { N2: 'OK', name: 'Oklahoma' },
    { N2: 'OR', name: 'Oregon' },
    { N2: 'PA', name: 'Pennsylvania' },
    { N2: 'RI', name: 'Rhode Island' },
    { N2: 'SC', name: 'South Carolina' },
    { N2: 'SD', name: 'South Dakota' },
    { N2: 'TN', name: 'Tennessee' },
    { N2: 'TX', name: 'Texas' },
    { N2: 'UT', name: 'Utah' },
    { N2: 'VT', name: 'Vermont' },
    { N2: 'VA', name: 'Virginia' },
    { N2: 'WA', name: 'Washington' },
    { N2: 'WV', name: 'West Virginia' },
    { N2: 'WI', name: 'Wisconsin' },
    { N2: 'WY', name: 'Wyoming' },
    { N2: 'AS', name: 'American Samoa' },
    { N2: 'DC', name: 'District of Columbia' },
    { N2: 'GU', name: 'Guam' },
    { N2: 'MP', name: 'Northern Mariana Islands' },
    { N2: 'PR', name: 'Puerto Rico' },
    { N2: 'VI', name: 'Virgin Islands' }
  ];
}
