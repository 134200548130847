import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

// rxjs
import {
  of as observableOf,
  throwError as observableThrowError,
  BehaviorSubject,
  Observable,
  of,
  throwError
} from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

// models
import { DeviceData } from './../models/device.model';
import { UserModel, UserTeamsModel } from './../models/user.model';
import { EventModel } from './../models/calendar.model';
import { SafeKeeperItemModel } from './../models/safekeeper.model';
import { PasswordComplexityModel } from '../models/password-complexity.model';

// providers
import { environment } from './../environments/environment';
import { Utils } from './utils.service';
import { LocalData } from './local-data.service';
import { Logger } from './logger.service';
import { UserSettings } from 'models/user-settings.model';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MfaVerificationResponse } from 'models/mfa-verification-response';
import { ApiResponseModel } from '../models/api-response.model';
import { PasswordUpdateModel } from '../models/password-update-model';
import { lowerCount, numberCount, specialCount, upperCount } from '../utilities/string-helpers';
import { UserPoliciesModel } from '../models/user-policies.model';
import { ExpiredPasswordUpdateModel } from '../models/expired-password-update.model';
import * as moment from 'moment';
import { ClientNameModel } from 'models/client.model';

@Injectable()
export class UserService {
  private source = 'UserService';
  private apiBaseUrl = environment.apiBaseUrl;
  private userSelf: UserModel;

  // subscribed for updates via local data service
  public userSource: BehaviorSubject<UserModel> = new BehaviorSubject(null);

  private passwordComplexity$: Observable<PasswordComplexityModel>;
  private passwordComplexityTimer: any;

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private localData: LocalData,
    private logger: Logger
  ) { }

  getUser(userId: string): Observable<UserModel> {
    this.logger.logInfo(this.source, 'getUser', 'API get /users/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/' + userId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUser', { status: res.status, statusText: res.statusText }, 'API get /users/:id');
        this.logger.logTrace(this.source, 'getUser', null, 'API get /users/:id');
        return this.utils.secureResponse(this.utils.adjustTimes(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUser', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserSelf(): Observable<UserModel> {
    this.logger.logInfo(this.source, 'getUserSelf', 'API get /users/self');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/self', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUserSelf', { status: res.status, statusText: res.statusText }, 'API get /users/self');
        this.logger.logTrace(this.source, 'getUserSelf', null, 'API get /users/self');
        return this.utils.secureResponse(this.utils.adjustTimes(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserSelf', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserSelfIfNotExists(): Observable<UserModel> {
    if (this.userSelf) {
      return observableOf(this.userSelf);
    } else {
      return this.getUserSelf()
        .pipe(
          tap(user_api => {
            this.userSelf = user_api;
          })
        );
    }
  }

  removeCachedUserSelf() {
    this.userSelf = null;
  }

  doesHaveRole(role: number): Observable<boolean> {
    return this.getUserSelfIfNotExists()
      .pipe(
        map(user => user.role === role),
      );
  }

  isBUCEnabled = (): Observable<boolean> => {
    return this.getUserSelfIfNotExists()
      .pipe(
        map(u => u.bucEnabled)
      )
  }

  getUserByEmail(email: string): Observable<UserModel> {
    this.logger.logInfo(this.source, 'getUserByEmail', 'API get /users/email=string');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/email=' + email, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUserByEmail', { status: res.status, statusText: res.statusText }, 'API get /users/email=string');
        this.logger.logTrace(this.source, 'getUserByEmail', null, 'API get /users/email=string');
        return this.utils.secureResponse(this.utils.adjustTimes(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserByEmail', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserIdByEmail(email: string): Observable<string> {
    this.logger.logInfo(this.source, 'getUserIdByEmail', 'API get /users/id-by-email/email=string');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/id-by-email/email=' + email, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUserIdByEmail', { status: res.status, statusText: res.statusText }, 'API get /users/email=string');
        this.logger.logTrace(this.source, 'getUserIdByEmail', null, 'API get /users/id-by-email/email=string');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserIdByEmail', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  isUserRecipient = (email: string): Observable<boolean> => {
    const options = this.utils.getJsonHttpOptions();
    let obj = {
      email: email
    }
    return this.http.post(this.apiBaseUrl + '/users/isuserrecipient', obj, options).pipe(
      map((res: HttpResponse<boolean>) => {
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'isUserRecipient', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  /**
   * @deprecated userNameExists is deprecated, please use userNameExistsV2 instead.
   */
  userNameExists(username: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'userNameExists', 'API POST /users/usernameExists');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/usernameExists/username=' + username, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'userNameExists', { status: res.status, statusText: res.statusText }, 'API put /users/usernameExists/username=string');
        this.logger.logTrace(this.source, 'userNameExists', null, 'API put /users/usernameExists/username=string');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'userNameExists', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  /**
   * @deprecated emailExists is deprecated, please use emailExistsV2 instead.
   */
  emailExists(email: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'emailExists', 'API POST /users/emailExists');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/emailExists/email=' + email, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'emailExists', { status: res.status, statusText: res.statusText }, 'API put /users/emailExists/email=string');
        this.logger.logTrace(this.source, 'emailExists', null, 'API put /users/emailExists/email=string');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'emailExists', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  userNameExistsV2(username: string): Observable<boolean> {
    this.logger.logInfo(this.source, 'userNameExistsV2', 'API POST /users/usernameExists/v2');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/usernameExists/v2/username=' + username, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'userNameExistsV2', { status: res.status, statusText: res.statusText }, 'API put /users/usernameExists/username=string');
        this.logger.logTrace(this.source, 'userNameExistsV2', null, 'API put /users/usernameExists/v2/username=string');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'userNameExistsV2', err, 'API Error /users/usernameExists/v2');
        return observableThrowError(err);
      }),);
  }

  emailExistsV2(email: string): Observable<boolean> {
    this.logger.logInfo(this.source, 'emailExistsV2', 'API POST /users/emailExists/v2');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/emailExists/v2/email=' + email, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'emailExistsV2', { status: res.status, statusText: res.statusText }, 'API put /users/emailExists/v2/email=string');
        this.logger.logTrace(this.source, 'emailExistsV2', null, 'API put /users/emailExists/v2/email=string');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'emailExistsV2', err, 'API Error /users/emailExists/v2');
        return observableThrowError(err);
      }),);
  }

  isValidDomain(domain: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'isValidDomain', 'API POST /utils/isvaliddomain');
    const body = { domain: domain };
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/utils/isvaliddomain', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'isValidDomain', { status: res.status, statusText: res.statusText }, 'API POST /utils/isvaliddomain');
        this.logger.logTrace(this.source, 'isValidDomain', null, 'API POST /utils/isvaliddomain');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserByEmail', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  createUser(user: UserModel): Observable<UserModel> {
    this.logger.logInfo(this.source, 'createUser', 'API post /users');
    const dob = moment(user.dob).format('YYYY-MM-DD[T]HH:mm:ss');
    const body = { ...user, dob };
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/users', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'createUser', { status: res.status, statusText: res.statusText }, 'API post /users');
        this.logger.logTrace(this.source, 'createUser', null, 'API post /users');
        return this.utils.secureResponse(this.utils.adjustTimes(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createUser', err, 'API Error');
        return observableThrowError(err);
      }));
  }

  updateUser(user: UserModel): Observable<UserModel> {
    this.logger.logInfo(this.source, 'updateUser', 'API put /users/:id');
    const dob = moment(user.dob).format('YYYY-MM-DD[T]HH:mm:ss');
    const body = { ...user, dob };
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/users/' + user.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateUser', { status: res.status, statusText: res.statusText }, 'API put /users/:id');
        this.logger.logTrace(this.source, 'updateUser', null, 'API put /users/:id');
        return this.utils.secureResponse(this.utils.adjustTimes(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateUser', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  uploadAvatar(userId: string, avatarFile: any): Observable<UserModel> {
    this.logger.logInfo(this.source, 'uploadAvatar', 'API put /users/:id/avatar');
    const body = new FormData();
    body.append('file', avatarFile);
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/users/' + userId + '/avatar', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'uploadAvatar', { status: res.status, statusText: res.statusText }, 'API put /users/:id/avatar');
        this.logger.logTrace(this.source, 'uploadAvatar', null, 'API put /users/:id/avatar');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'uploadAvatar', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserTeams(userId: string): Observable<UserTeamsModel[]> {
    this.logger.logInfo(this.source, 'getUserTeams', 'API get /users/:id/teams');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/' + userId + '/teams', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUserTeams', { status: res.status, statusText: res.statusText }, 'API get /users/:id/teams');
        this.logger.logTrace(this.source, 'getUserTeams', null, 'API get /users/:id/teams');
        return this.utils.adjustTimesForArray(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserTeams', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getTeamsForCreateMessages(): Observable<UserTeamsModel[]> {
    this.logger.logInfo(this.source, 'getTeamsForCreateMessages', 'API get /users/:id/teams-for-create-messages');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/teams-for-create-messages', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeamsForCreateMessages', { status: res.status, statusText: res.statusText }, 'API get /users/:id/teams');
        this.logger.logTrace(this.source, 'getTeamsForCreateMessages', null, 'API get /users/:id/teams-for-create-messages');
        return this.utils.adjustTimesForArray(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeamsForCreateMessages', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updatePassword(updateModel: PasswordUpdateModel): Observable<ApiResponseModel | boolean> {
    this.logger.logInfo(this.source, 'updatePassword', 'API put /users/password/update');
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/users/password/update', updateModel, options).pipe(
      map((res: HttpResponse<ApiResponseModel | boolean>) => {
        this.logger.logDebug(this.source, 'updatePassword', { status: res.status, statusText: res.statusText }, 'API put /users/password/update');
        this.logger.logTrace(this.source, 'updatePassword', null, 'API put /users/password/update');
        return res.body || res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updatePassword', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateExpiredPassword = (updateModel: ExpiredPasswordUpdateModel): Observable<ApiResponseModel> => {
    this.logger.logInfo(this.source, 'updatePassword', 'API put /users/password/update-expired');
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/users/password/update-expired', updateModel, options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        this.logger.logDebug(this.source, 'updateExpiredPassword', { status: res.status, statusText: res.statusText }, 'API put /users/password/update-expired');
        this.logger.logTrace(this.source, 'updateExpiredPassword', null, 'API put /users/password/update-expired');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateExpiredPassword', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  sendEmail(userId: string, templateId: string, creatorId: string, receiverId: string, vars: string, var2?: string, calEvent?: EventModel, note?: SafeKeeperItemModel, var3?: string, when?: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'sendEmail', 'API put /users/:id/sendemail');
    const body = { templateId: templateId, creatorId: creatorId, receiverId: receiverId, vars: vars, var2: var2, calEvent: calEvent, note: note, var3, when };
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/users/' + userId + '/sendemail', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'sendEmail', { status: res.status, statusText: res.statusText }, 'API put /users/:id/sendemail');
        this.logger.logTrace(this.source, 'sendEmail', null, 'API put /users/:id/sendemail');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'sendEmail', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  register(email: string, displayName: string, password: string, device: DeviceData, uuid: string, promoCode: string): Observable<boolean> {
    this.logger.logInfo(this.source, 'register', 'API POST /tokens/register');
    const body = { username: email, displayName: displayName, password: password, device: JSON.stringify(device), uuid: uuid, promoCode: promoCode };
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/tokens/register', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'register', { status: res.status, statusText: res.statusText }, 'API put /tokens/register');
        this.logger.logTrace(this.source, 'register', null, 'API put /tokens/register');
        this.logger.logTrace(this.source, 'register', null, 'API put /tokens/register');
        return res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'register', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  // temporarily using different name
  register2(user: UserModel, password: string, device: DeviceData, uuid: string, token: string): Observable<ApiResponseModel | boolean> {
    this.logger.logInfo(this.source, 'register2', 'API POST /tokens/register-bh');

    const body = {
      displayName: user.displayName,
      firstName: user.firstName,
      lastName: user.lastName,
      password,
      country: user.addressCountry,
      addressPostalCode: user.addressPostalCode,
      dob: moment(user.dob).format('YYYY-MM-DD[T]HH:mm:ss'),
      cellPhone: user.cellPhone,
      tosAccepted: user.tosAccepted,
      device: JSON.stringify(device),
      uuid,
      timeZone: user.timeZone
    };
    let params = new HttpParams({ fromObject: { token } });
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.post(this.apiBaseUrl + '/tokens/register-bh', body, options).pipe(
      map((res: HttpResponse<ApiResponseModel | boolean>) => {
        this.logger.logDebug(this.source, 'register2', {
          status: res.status,
          statusText: res.statusText
        }, 'API put /tokens/register-bh');
        this.logger.logTrace(this.source, 'register2', null, 'API put /tokens/register-bh');
        return res.body || res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'register2', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateUserWalkthrough(user: UserModel, walkthrough: number) {
    user.walkthrough = walkthrough;
    return this.updateUser(user)
      .pipe(
        tap((user_api) => {
          this.logger.logInfo(this.source, 'updateUserWalkthrough', 'updated user walkthrough to ' + walkthrough);
          this.userSource.next(user_api);
        }),
        catchError((err) => {
          this.logger.logError(this.source, 'updateUserWalkthrough', err, 'error updating user walkthrough');
          return err
        }),);
  }

  resetPassword(email: string, recaptchaToken: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'resetPassword', 'API get /tokens/resetpassword/email=string');
    const headers = {
      'g-recaptcha-response': recaptchaToken,
    }
    return this.http.get(this.apiBaseUrl + '/tokens/resetpassword/email=' + email, { observe: 'response', headers: headers }).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'resetPassword', { status: res.status, statusText: res.statusText }, 'API put /tokens/resetpassword/email=string');
        this.logger.logTrace(this.source, 'resetPassword', null, 'API put /tokens/resetpassword/email=string');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'resetPassword', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  // admin
  listAllUsers(roles: number[] = null): Observable<any[]> {
    this.logger.logInfo(this.source, 'listAllUsers', 'API get /admin/users');
    const options = this.utils.getJsonHttpOptions();
    let url = this.apiBaseUrl + '/admin/users';
    if (roles) {
      const rolesDelta = roles.map(r => `roles=${r}`).join('&');
      url += `?${rolesDelta}`
    }
    return this.http.get(url, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'listAllUsers', { status: res.status, statusText: res.statusText }, 'API get /admin/users');
        this.logger.logTrace(this.source, 'listAllUsers', null, 'API get /admin/users');
        return this.utils.adjustTimesForArray(res.body.items);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'listAllUsers', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateUserStatus(user: UserModel): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'updateUserStatus', 'API put /admin/users/:id/status');
    const body = user;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/admin/users/' + user.id + '/status', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateUserStatus', { status: res.status, statusText: res.statusText }, 'API put /admin/users/:id/status');
        this.logger.logTrace(this.source, 'updateUserStatus', null, 'API put /admin/users/:id/status');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateUserStatus', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserSettings(userId: string): Observable<UserSettings> {
    this.logger.logInfo(this.source, 'getUserSettings', 'API get /users/getusersettings');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/getusersettings?userId=' + userId, options).pipe(
      map((res: HttpResponse<any>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserSettings', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateNotificationSetting(notificationName: string, value: boolean, userId: string) {
    this.logger.logInfo(this.source, 'updateNotificationSetting', 'API get /users/updatenotificationsetting');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/updatenotificationsetting?notificationName=' + notificationName + '&value=' + value + '&userId=' + userId, options).pipe(
      map((res: HttpResponse<any>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserSettings', err, 'API Error');
        return observableThrowError(err);
      }));
  }

  emailConfirmation(token) {
    this.logger.logInfo(this.source, 'emailConfirmation', 'API GET /tokens/confirm_email');
    return this.http.get(this.apiBaseUrl + '/tokens/confirm_email?token=' + token, { observe: 'response' }).pipe(
      map((res: HttpResponse<any>) => {
        if (res.status === 200) {
          return { 'valid': true };
        } else {
          return { 'result': false };
        }
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'emailConfirmation', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

  usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.userNameExistsV2(control.value).pipe(
        map(exists => {
            return exists ? { usernameExists: true } : null
        }),
        catchError(err => {
          return of({ errorValidating: true });
        })
      );
    };
  }

  emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.emailExistsV2(control.value).pipe(
        map(exists => {
          return exists ? { emailExists: true } : null
        }),
        catchError(err => {
          return of({ errorValidating: true });
        })
      );
    };
  }

  acceptTAC() {
    let acceptUrl = '/users/accept'
    this.logger.logInfo(this.source, 'acceptTAC', 'API get ' + acceptUrl);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + acceptUrl, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res
        }), catchError((err: any) => {
          this.logger.logError(this.source, 'acceptTAC', err, 'API Error');
          return Observable.throw(err);
        }));
  }

  getPasswordComplexity = (token?: string): Observable<PasswordComplexityModel> => {
    const options = this.utils.getJsonHttpOptions();
    const url = token
      ? `${this.apiBaseUrl}/authentication-policies/password-complexity/get/${token}`
      : `${this.apiBaseUrl}/authentication-policies/password-complexity/get`;
    return this.http.get(url, options).pipe(
      map((res: HttpResponse<PasswordComplexityModel>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getPasswordComplexity', err, 'API Error');
        return throwError(err);
      }),
      shareReplay(1),
    );
  }

  getRecipientPasswordComplexity = (teamId: string, recipientId: string): Observable<PasswordComplexityModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/authentication-policies/password-complexity/get/recipient/${teamId}/${recipientId}`, options).pipe(
      map((res: HttpResponse<PasswordComplexityModel>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getPasswordComplexity', err, 'API Error');
        return throwError(err);
      }),
      shareReplay(1),
    );
  }

  resetPasswordComplexity(token?: string, teamId?: string, recipientId?: string) {
    const currentUser = this.localData.getUser()
    this.passwordComplexity$ = recipientId && currentUser && currentUser.id !== recipientId ? this.getRecipientPasswordComplexity(teamId, recipientId) : this.getPasswordComplexity(token)
    clearTimeout(this.passwordComplexityTimer);
    this.passwordComplexityTimer = setTimeout(() => {
      this.resetPasswordComplexity(token, teamId, recipientId);
    }, 60 * 1000);
  }

  clearPasswordComplexity = () => this.passwordComplexity$ = null

  passwordValidator(token?: string, teamId?: string, recipientId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const passwordComplexity$ = this.getPasswordComplexityObservable(token, teamId, recipientId);
      return passwordComplexity$.pipe(
        map((passwordComplexityModel: PasswordComplexityModel) => {
          const validationErrors: ValidationErrors = {};
          const value = control.value;

          if (!value || value.length < passwordComplexityModel.minLength) {
            validationErrors['minLength'] = {
              'min': passwordComplexityModel.minLength,
              'actual': value?.length || 0,
              'msg': `Must be at least ${passwordComplexityModel.minLength} characters.`,
            }
          }

          const lowerCount_ = lowerCount(value);
          if (lowerCount_ < passwordComplexityModel.minLower) {
            validationErrors['minLower'] = {
              'min': passwordComplexityModel.minLower,
              'actual': lowerCount_,
              'msg': `Must contain at least ${passwordComplexityModel.minLower} lower case characters.`,
            }
          }

          const upperCount_ = upperCount(value);
          if (upperCount_ < passwordComplexityModel.minUpper) {
            validationErrors['minUpper'] = {
              'min': passwordComplexityModel.minUpper,
              'actual': upperCount_,
              'msg': `Must contain at least ${passwordComplexityModel.minUpper} upper case characters.`,
            }
          }

          const numberCount_ = numberCount(value);
          if (numberCount_ < passwordComplexityModel.minNumber) {
            validationErrors['minNumber'] = {
              'min': passwordComplexityModel.minNumber,
              'actual': numberCount_,
              'msg': `Must contain at least ${passwordComplexityModel.minNumber} numbers.`,
            }
          }

          const specialCount_ = specialCount(value);
          if (specialCount_ < passwordComplexityModel.minSpecial) {
            validationErrors['minSpecial'] = {
              'min': passwordComplexityModel.minSpecial,
              'actual': specialCount_,
              'msg': `Must contain at least ${passwordComplexityModel.minSpecial} special characters.`,
            }
          }

          return Object.keys(validationErrors).length === 0 ? null : validationErrors;
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, 'passwordValidator', err, 'API Error');
          return throwError(err);
        }),
      );
    };
  }

  private getPasswordComplexityObservable(token?: string, teamId?: string, recipientId?: string) {
    if (!this.passwordComplexity$) {
      this.resetPasswordComplexity(token, teamId, recipientId);
    }
    return this.passwordComplexity$;
  }

  getUserPolicies = (): Observable<UserPoliciesModel> => {
    this.logger.logInfo(this.source, 'getUserPolicies', 'API get api/authentication-policies');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/authentication-policies/get', options).pipe(
      map((res: HttpResponse<UserPoliciesModel>) => {
        this.logger.logDebug(this.source, 'getUserPolicies', { status: res.status, statusText: res.statusText }, 'API get /users/:id');
        this.logger.logTrace(this.source, 'getUserPolicies', null, 'API get api/authentication-policies');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserPolicies', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  checkRecipient = (teamId?: string, userId?: string): Observable<boolean> => {
    const currentUser = this.localData.getUser()
    return userId && currentUser && currentUser.id !== userId ? this.isOtherUserRecipient(teamId, userId) : this.amIRecipient()
  }

  amIRecipient = (): Observable<boolean> => {
    this.logger.logInfo(this.source, 'amIRecipient', 'API get /users/am-i-recipient');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/am-i-recipient', options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'amIRecipient', { status: res.status, statusText: res.statusText }, 'API get /users/am-i-recipient');
        this.logger.logTrace(this.source, 'amIRecipient', null, 'API get /users/am-i-recipient');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'amIRecipient', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  isOtherUserRecipient = (teamId: string, recipientId: string): Observable<boolean> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/users/is-user-recipient/${teamId}/${recipientId}`, options).pipe(
      map((res: HttpResponse<boolean>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'isOtherUserRecipient', err, 'API Error');
        return throwError(err);
      }),
      shareReplay(1),
    );
  }

  needsAnotherVerification(): Observable<MfaVerificationResponse> {
    this.logger.logInfo(this.source, 'needsAnotherVerification', 'API get /users/needs-another-verification');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/needs-another-verification', options).pipe(
      map((res: HttpResponse<MfaVerificationResponse>) => {
        this.logger.logDebug(this.source, 'needsAnotherVerification', { status: res.status, statusText: res.statusText }, 'API get /users/needs-another-verification');
        this.logger.logTrace(this.source, 'needsAnotherVerification', null, 'API get /users/needs-another-verification');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'needsAnotherVerification', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  sendPhoneNumberVerification(phoneNumber: string = null): Observable<boolean> {
    this.logger.logInfo(this.source, 'sendPhoneNumberVerification', 'API get /users/send-phone-number-verification');
    const options = this.utils.getJsonHttpOptions();
    const body = {
      phoneNumber
    };
    return this.http.post(this.apiBaseUrl + '/users/send-phone-number-verification', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'sendPhoneNumberVerification', { status: res.status, statusText: res.statusText }, 'API get /users/send-phone-number-verification');
        this.logger.logTrace(this.source, 'sendPhoneNumberVerification', null, 'API get /users/send-phone-number-verification');
        return res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'sendPhoneNumberVerification', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  verifyPhoneNumber(code: string, phoneNumber: string = null): Observable<boolean> {
    this.logger.logInfo(this.source, 'verifyPhoneNumber', 'API get /users/verify-phone-number');
    const options = this.utils.getJsonHttpOptions();
    const body = {
      phoneNumber,
      code
    };
    return this.http.post(this.apiBaseUrl + '/users/verify-phone-number', body, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'verifyPhoneNumber', { status: res.status, statusText: res.statusText }, 'API get /users/verify-phone-number');
        this.logger.logTrace(this.source, 'verifyPhoneNumber', null, 'API get /users/verify-phone-number');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'verifyPhoneNumber', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  sendLoginVerification(): Observable<boolean> {
    this.logger.logInfo(this.source, 'sendLoginVerification', 'API get /users/send-login-verification');
    const options = this.utils.getJsonHttpOptions();
    const body = {
    };
    return this.http.post(this.apiBaseUrl + '/users/send-login-verification', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'sendLoginVerification', { status: res.status, statusText: res.statusText }, 'API get /users/send-login-verification');
        this.logger.logTrace(this.source, 'sendLoginVerification', null, 'API get /users/send-login-verification');
        return res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'sendLoginVerification', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  verifyLogin(code: string): Observable<boolean> {
    this.logger.logInfo(this.source, 'verifyLogin', 'API get /users/verify-login');
    const options = this.utils.getJsonHttpOptions();
    const body = {
      code
    }
    return this.http.post(this.apiBaseUrl + '/users/verify-login', body, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'verifyLogin', { status: res.status, statusText: res.statusText }, 'API get /users/verify-login');
        this.logger.logTrace(this.source, 'verifyLogin', null, 'API get /users/verify-login');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'verifyLogin', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateUserTwoFactor = (state: number): Observable<boolean> => {
    this.logger.logInfo(this.source, 'updateUserTwoFactor', 'API put /authentication-policies/two-factor/self/update/');
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + `/authentication-policies/two-factor/self/update/${state}`, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'updateUserTwoFactor', { status: res.status, statusText: res.statusText },
          'API put /users/password/update');
        this.logger.logTrace(this.source, 'updateUserTwoFactor', null, 'API put /authentication-policies/two-factor/self/update/');
        return res.body || res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateUserTwoFactor', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateUserPasswordRotation = (state: number): Observable<boolean> => {
    this.logger.logInfo(this.source, 'updateUserPasswordRotation', 'API put /authentication-policies/password-complexity/self/update/');
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + `/authentication-policies/password-complexity/self/update/${state}`, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'updateUserPasswordRotation', { status: res.status, statusText: res.statusText },
          'API put /password-complexity/self/update/');
        this.logger.logTrace(this.source, 'updateUserPasswordRotation', null, 'API put /authentication-policies/password-complexity/self/update/');
        return res.body || res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateUserPasswordRotation', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserClientName = (): Observable<ClientNameModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + `/users/user-client-name`, options).pipe(
      map((res: HttpResponse<ClientNameModel>) => {
        this.logger.logDebug(this.source, 'userClientName', { status: res.status, statusText: res.statusText },
          'API get /users/user-client-name');
        this.logger.logTrace(this.source, 'userClientName', null, 'API get /users/user-client-name');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'userClientName', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getUserOriginalClientName = (): Observable<ClientNameModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + `/users/user-original-client-name`, options).pipe(
      map((res: HttpResponse<ClientNameModel>) => {
        this.logger.logDebug(this.source, 'getUserOriginalClientName', { status: res.status, statusText: res.statusText },
          'API get /users/user-original-client-name');
        this.logger.logTrace(this.source, 'getUserOriginalClientName', null, 'API get /users/user-original-client-name');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserOriginalClientName', err, 'API Error');
        return observableThrowError(err);
      }),);
  }
}
